<template>
  <ToolsFormEdgeDistanceEC
    :prop-power="power"
    :prop-bearing-width="bearingWidth"
    :prop-bearing-length="bearingLength"
    :prop-bearing-thickness="bearingThickness"
    :prop-holes-diameter="holesDiameter"
    :prop-holes-count="holesCount"
    :disable-inputs="disableInputs"
  />
</template>

<script>
import ToolsFormEdgeDistanceEC from '../../components/tools/ToolsFormEdgeDistanceEC.vue';

export default {
  components: {
    ToolsFormEdgeDistanceEC,
  },
  data() {
    return {
      bearingWidth: this.$store.state.fixedBearingWidth,
      bearingLength: this.$store.state.fixedBearingLength,
      bearingThickness: this.$store.state.fixedBearingThickness,
      power: this.$store.state.fixedBearingPressureForce,
      holesDiameter: this.$store.state.fixedBearingHolesDiameter,
      holesCount: this.$store.state.fixedBearingHolesCount,
      disableInputs: [
        'power',
        'bearingWidth',
        'bearingLength',
        'bearingThickness',
        'holesDiameter',
        'holesCount',
      ],
    };
  },
};
</script>

<style scoped lang="scss">

</style>
